import { getPage } from 'data-interface/sanity'
import styles from 'styles/modules/404.module.scss'
import { _404_SLUG } from 'data/routes'

export default function Page404({ data }) {
  return <div className={styles._404}>{data.title}</div>
}

export const getStaticProps = async context => {
  const { preview: isPreview = false } = context
  const slug = _404_SLUG

  const data = await getPage(slug, 'page')

  return {
    props: {
      data,
      isPreview,
    },
    revalidate: parseInt(process.env.REVALIDATE),
  }
}
